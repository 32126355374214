// import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import logoWhite from './logo_white.svg';
import abstract from './pipes2.jpg'; 

const Tagline = styled.div`
  font-size: 14px; 
  color: #ffffff;
  text-align: center;
  margin: 20px 0; // Adjust spacing as needed
  max-width: 600px;
  padding: 0 20px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 12px;
    margin: 15px 0;
    max-width: 90%;
  }
`;



export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: 
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.18)), // Dark overlay
    url(${abstract}) no-repeat center center;
  background-size: cover;
  animation: ${fadeIn} 1s ease-in;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;


const NavContainer = styled.div`
  display: flex;
  gap: 60px;
  margin-top: 20px;
  font-size: 1.2rem !important;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    margin-top: 4rem; // Increased from 15px to 40px for more spacing on mobile
  }
`;


const NavLink = styled.a`
  color: #ffffff; // White text for links
  text-decoration: none;
  font-size: 18px;
  position: relative;
  padding: 5px 10px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px; // Increased thickness for visibility
    bottom: 0;
    left: 0;
    background-color: #ffffff; 
    transition: width 0.4s ease;
  }

  &:hover:after {
    width: 100%;
  }
`;


const Logo = styled.img`
  width: 160px;
  margin-bottom: 30px; // Reduced for better spacing
  
  @media (max-width: 768px) {
    width: 150px;
    margin-bottom: 20px;
  }
`;



const SocialContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;


const SocialLink = styled.a`
  opacity: 0.7;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #ffffff; // White for visibility
  }
`;


function HomePage() {
  // const navigate = useNavigate();

  return (
    <Container>
      <header>
        <h1 style={{ position: 'absolute', left: '-9999px' }}>
          Information Company - Technology & Society Knowledge Hub
        </h1>

        <SocialContainer aria-label="Social Media Links">
          {/* <SocialLink 
            href="https://www.instagram.com/information.company.global" 
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Follow us on Instagram">
            <svg viewBox="0 0 24 24" aria-hidden="true">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </SocialLink> */}
          <SocialLink 
            href="https://linkedin.com/company/information-company" 
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Connect with us on LinkedIn">
            <svg viewBox="0 0 24 24" aria-hidden="true">
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
          </SocialLink>
          <SocialLink 
            href="mailto:hello@info-com.io" 
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Email us">
            <svg viewBox="0 0 24 24" aria-hidden="true">
              <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
            </svg>
          </SocialLink>
        </SocialContainer>

        <Logo src={logoWhite} alt="Information Company Logo" />
      </header>

      <main>
        <Tagline>
          The Meta Media.
        </Tagline>

        <nav aria-label="Main navigation">
          <NavContainer>
            <NavLink 
              href="https://financial-portfolio.io" 
              target="_blank" 
              rel="noopener noreferrer">
              Financial Research
            </NavLink>
            {/* <NavLink onClick={() => navigate('/coming-soon')}>
              Law
            </NavLink> */}
            <NavLink 
              href="https://info-com.io" 
              target="_blank" 
              rel="noopener noreferrer">
              Media Exploration
            </NavLink>
            <NavLink 
              href="https://info-com.io" 
              target="_blank" 
              rel="noopener noreferrer">
              Intelligence Orchestration
            </NavLink>
            {/* <NavLink onClick={() => navigate('/coming-soon', { state: { from: 'news' } })}>
              News & Opinion
            </NavLink> */}
          </NavContainer>
        </nav>
      </main>
    </Container>
  );
}

export default HomePage;