import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import HomePage from './components/HomePage';
import ComingSoon from './components/ComingSoon';
import Article from './components/Article';

// Global styles including scrollbar modifications
const GlobalStyle = createGlobalStyle`
  /* Scrollbar customization */
  ::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1); /* Background of the scrollbar track */
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3); /* Scrollbar thumb color */
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5); /* Thumb color when hovered */
  }

  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.3) rgba(255, 255, 255, 0.1); /* Thumb and track colors */
  }

  /* Additional global styles */
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #121212; /* Background for dark theme */
    color: #ffffff; /* Default text color */
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
`;


const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1; /* Ensures the content takes up remaining height */
`;

function App() {
  return (
    <>
      {/* Apply global styles */}
      <GlobalStyle />

      <BrowserRouter>
        <AppContainer>
          {/* Content section */}
          <ContentContainer>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/coming-soon" element={<ComingSoon />} />
              <Route path="/articles/:slug" element={<Article />} />
            </Routes>
          </ContentContainer>


        </AppContainer>
      </BrowserRouter>
    </>
  );
}

export default App;
